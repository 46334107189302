<template>
    <div class="ciyou">
        <div class="description">
            <div class="avatar" />
            <div class="content">
                <div class="triangle" />
                <h2>您的反馈和建议，是对我们最大的支持</h2>
                <p>尊敬的家长，若有任何关于 <strong>环境设置</strong>、<strong>人员投诉</strong>、<strong>活动建议等</strong> 的意见或建议，均可提交留言反馈，您的声音将会直通慈幼服务中心的管理团队，并会尽快给予答复，欢迎畅所欲言！~</p>
            </div>
        </div>
        <van-form @submit="onSubmit">
            <van-cell-group class="van-hairline--top-bottom">
                <van-field
                    readonly
                    clickable
                    name="streetPicker"
                    :value="runtimeData.type"
                    label="问题类型"
                    placeholder="点击选择问题类型"
                    :rules="[{ required: true, message: '请选择您要反馈问题的类型' }]"
                    @click="showPicker.type = true"
                />
                <van-field
                    readonly
                    clickable
                    name="storePicker"
                    :value="runtimeData.store"
                    label="反馈门店"
                    placeholder="点击选择反馈门店"
                    :rules="[{ required: true, message: '请选择反馈的门店' }]"
                    @click="showPicker.store = true"
                />
                <van-field
                    v-model="form.content"
                    rows="5"
                    autosize
                    label="留言"
                    type="textarea"
                    placeholder="请输入您的反馈留言"
                    :rules="[{ required: true, message: '反馈留言不能为空哦~欢迎畅所欲言~' }]"
                />
            </van-cell-group>
            <van-cell-group class="van-hairline--top-bottom" style="margin-top: 15px">
                <van-cell>
                    <template #title>
                        <span class="tips"><van-icon class="icon" name="info" />为了能尽快给予您答复，请留下您的联系方式。</span>
                    </template>
                </van-cell>
                <van-field v-model="form.childName" label="儿童姓名" placeholder="请输入儿童姓名" maxlength="20" :rules="[{ required: true, message: '请填写儿童姓名' }]" />
                <van-field v-model="form.contactPhone" label="手机号码" placeholder="请输入联系手机号码" maxlength="11" :rules="[{ required: true, validator: checkPhone, message: '请填写正确的联系手机号码' }]" />
            </van-cell-group>
            <div class="submit-btn">
                <van-button block type="info" :loading="submitLoading" native-type="submit">
                    提交
                </van-button>
            </div>

            <!-- 门店选择器 -->
            <van-popup v-model="showPicker.store" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns.store"
                    @confirm="onConfirmStore"
                    @cancel="showPicker.store = false"
                />
            </van-popup>
            <!-- 问题类型选择器 -->
            <van-popup v-model="showPicker.type" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns.type"
                    @confirm="onConfirmType"
                    @cancel="showPicker.type = false"
                />
            </van-popup>
        </van-form>
    </div>
</template>

<script>
import { Http } from '@/utils/axios';
import { STORE, FEEDBACKTYPE } from '@/utils/constant';

export default {
    name: "Home",
    data() {
        return {
            submitLoading: false,
            runtimeData: {
                store: '',
                type: ''
            },
            form: {
                content: '',
                storeId: null,
                type: null,
                childName: '',
                contactPhone: ''
            },
            showPicker: {
                store: false,
                type: false
            },
            columns: {
                store: Object.keys(STORE).map(key => ({ value: Number(key), text: STORE[key] })),
                type: Object.keys(FEEDBACKTYPE).map(key => ({ value: Number(key), text: FEEDBACKTYPE[key] }))
            }
        };
    },
    methods: {
        checkPhone(val) {
            return /^1[0-9]{10}$/.test(val);
        },
        onConfirmStore(data) {
            this.form.storeId = data.value;
            this.runtimeData.store = data.text;
            this.showPicker.store = false;
        },
        onConfirmType(data) {
            this.form.type = data.value;
            this.runtimeData.type = data.text;
            this.showPicker.type = false;
        },
        onSubmit() {
            Http.put(`/api/wx/feedback`, this.form).then(({ data }) => {
                this.$dialog.alert({
                    title: '提交成功',
                    message: `尊敬的${this.form.childName}家长，您宝贵的意见和建议已提交至我们的管理团队，感谢您对慈幼服务中心的支持！~`,
                    theme: 'round-button',
                    confirmButtonColor: '#48be50'
                }).then(() => {
                    this.runtimeData.store = '';
                    this.runtimeData.type = '';
                    this.form.content = '';
                    this.form.storeId = null;
                    this.form.type = null;
                    this.form.childName = '';
                    this.form.contactPhone = '';
                });
            })
            .catch(err => {
                if (err.response.status === 404) {
                    // 儿童信息已被删除
                    this.step = 1;
                    window.localStorage.clear();
                } else {
                    this.$toast.fail('系统异常');
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.ciyou {
    position: relative;
    .description {
        display: flex;
        align-items: flex-end;
        padding: 20px 20px 0;
        margin-bottom: 15px;
        background-color: #c2d8b9;
        border-bottom: 2px solid #8a819d;
        .avatar {
            height: 75px;
            width: 50px;
            background-image: url('~@assets/cs.png');
            background-size: 100% 100%;
        }
        .content {
            position: relative;
            width: calc(100% - 70px);
            padding: 10px;
            margin:  0 0 20px 20px;
            background-color: #fff;
            font-size: 13px;
            line-height: 1.5;
            border-radius: 3px;

            .triangle {
                contain: '';
                position: absolute;
                left: -12px;
                bottom: 20px;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-right: 12px solid #fff;
                border-bottom: 8px solid transparent;
            }

            h2 {
                font-size: 15px;
                line-height: 1.5;
                margin: 0 0 10px;
                color: #72bb3a;
            }
            p {
                margin: 0;
            }
            strong {
                color: #72bb3a;
            }
        }
    }
    .tips {
        font-size: 12px;
        color: @gray-6;
        .icon {
            vertical-align: top;
            margin: 7px 6px 0 0;
        }
    }
    .submit-btn {
        margin: 20px;
    }
}
</style>