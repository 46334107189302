import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Form from '../views/Form.vue';
import Survey from '../views/Survey.vue';
import Teacher from '../views/Teacher.vue';
import TeacherList from '../views/TeacherList.vue';
import Feedback from '../views/Feedback.vue';
import OmsIndex from '../views/oms/Index.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/signup/child',
        name: 'Home',
        component: Home,
    },
    {
        path: '/signup/child/form',
        name: 'Form',
        component: Form,
        beforeEnter: (to, from, next) => {
            if (to.query.storeId) {
                next();
            }
        }
    },
    {
        path: '/signup/child/survey/:cid',
        name: 'Survey',
        component: Survey,
        beforeEnter: (to, from, next) => {
            if (to.params.cid) {
                next();
            }
        }
    },
    {
        path: '/teachers/:id',
        name: 'Teacher',
        component: Teacher
    },
    {
        path: '/teachers',
        name: 'TeacherList',
        component: TeacherList
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: Feedback
    },
    {
        path: '/oms/index',
        name: 'OmsIndex',
        component: OmsIndex,
        meta: { dingTalkCheck: true }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
