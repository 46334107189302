<template>
    <div class="form">
        <van-form v-if="step === 1" @submit="onSubmit">
            <van-cell-group title="基础信息">
                <van-field v-model="form.name" label="儿童姓名" placeholder="请输入儿童姓名" maxlength="20" :rules="[{ required: true, message: '请填写儿童姓名' }]" />
                <van-field v-model="form.phone" label="家长手机" placeholder="请输入联系手机号码" maxlength="11" :rules="[{ required: true, validator: checkPhone, message: '请填写正确的联系手机号码' }]" />
                <van-field name="gender" label="性别">
                    <template #input>
                        <van-radio-group v-model="form.gender" icon-size="16px" direction="horizontal">
                            <van-radio :name="1">
                                男孩
                            </van-radio>
                            <van-radio :name="2">
                                女孩
                            </van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="form.birthday"
                    label="出生日期"
                    placeholder="点击选择日期"
                    :rules="[{ required: true, message: '请选择出生日期' }]"
                    @click="showPicker.birthday = true"
                />
                <van-cell>
                    <span slot="title" class="van-field__label">感谢您的支持，请问您是通过哪种方式了解到慈幼？</span>
                </van-cell>
                <van-field
                    readonly
                    clickable
                    name="sourceTypePicker"
                    :value="runtimeData.sourceType"
                    placeholder="点击选择了解慈幼的方式"
                    :rules="[{ required: true, message: '请选择了解慈幼的方式' }]"
                    @click="showPicker.sourceType = true"
                />
            </van-cell-group>
            <van-cell-group title="诊断信息">
                <van-field name="gender" label="是否确诊">
                    <template #input>
                        <van-switch v-model="runtimeData.isDiagnosis" size="20px" />
                    </template>
                </van-field>
                <template v-if="runtimeData.isDiagnosis">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="runtimeData.hospital"
                        label="确诊医院"
                        placeholder="点击选择确诊医院"
                        @click="showPicker.hospital = true"
                    />
                    <van-field
                        v-if="runtimeData.hospital === '其他'"
                        v-model="form.hospital"
                        label="确诊医院补充"
                        placeholder="请输入确诊医院名称"
                        maxlength="20"
                        :rules="[{ required: true, message: '请输入确诊医院名称' }]"
                    />
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="runtimeData.diagnosisType"
                        label="诊断"
                        placeholder="点击选择诊断"
                        @click="showPicker.diagnosis = true"
                    />
                    <van-field v-model="form.diagnosisRemark" label="诊断补充" maxlength="255" placeholder="请输入诊断补充信息" />
                </template>
                <van-field v-model="form.drugs" label="目前药物治疗" maxlength="255" placeholder="请输入目前服用药物" />
            </van-cell-group>
            <van-cell-group title="现居住地信息">
                <van-field
                    readonly
                    clickable
                    name="areaPicker"
                    :value="runtimeData.area"
                    label="地区"
                    placeholder="点击选择地区"
                    :rules="[{ required: true, message: '请选择地区' }]"
                    @click="showPicker.area = true"
                />
                <van-field
                    v-if="columns.street.length > 0"
                    readonly
                    clickable
                    name="streetPicker"
                    :value="runtimeData.street"
                    label="街道"
                    placeholder="点击选择街道"
                    :rules="[{ required: true, message: '请选择街道' }]"
                    @click="onStreetClick"
                />
            </van-cell-group>
            <van-cell-group title="其他信息">
                <van-field v-model="form.encouragement" label="奖励物" maxlength="2000" placeholder="请输入奖励物" />
                <van-cell>
                    <template #title>
                        <span class="tips"><van-icon class="icon" name="info" />奖励物指的是孩子平常最喜欢的零食、玩具或其他事物，适用于作为干预训练时的奖励强化物。</span>
                    </template>
                </van-cell>
                <van-field v-model="form.allergy" label="过敏原" maxlength="255" placeholder="请输入过敏原" />
                <van-cell>
                    <template #title>
                        <span class="tips"><van-icon class="icon" name="info" />除了生理性的过敏原，若孩子有特别恐惧或厌恶的事物，也请一并填写记录便于日后规避。</span>
                    </template>
                </van-cell>
                <van-field name="gender" label="有干预史">
                    <template #input>
                        <van-switch v-model="form.training" :active-value="1" :inactive-value="0" size="20px" />
                    </template>
                </van-field>
                <template v-if="form.training">
                    <van-field v-model="form.trainingTime" label="曾干预时长" maxlength="10" placeholder="请输入曾干预时长" />
                    <van-field v-model="form.trainingSchool" label="曾干预机构" maxlength="255" placeholder="请输入曾干预机构" />
                </template>
            </van-cell-group>
            <div class="submit-btn">
                <van-button block type="info" :loading="submitLoading" native-type="submit">
                    提交
                </van-button>
            </div>
            <!-- 日期选择器 -->
            <van-popup v-model="showPicker.birthday" position="bottom">
                <van-datetime-picker
                    type="date"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmBirthday"
                    @cancel="showPicker.birthday = false"
                />
            </van-popup>
            <!-- 病种选择器 -->
            <van-popup v-model="showPicker.diagnosis" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns.diagnosisType"
                    @confirm="onConfirmDiagnosis"
                    @cancel="showPicker.diagnosis = false"
                />
            </van-popup>
            <!-- 来源选择器 -->
            <van-popup v-model="showPicker.sourceType" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns.sourceType"
                    @confirm="onConfirmSourceType"
                    @cancel="showPicker.sourceType = false"
                />
            </van-popup>
            <!-- 医院选择器 -->
            <van-popup v-model="showPicker.hospital" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns.hospital"
                    @confirm="onConfirmHospital"
                    @cancel="showPicker.hospital = false"
                />
            </van-popup>
            <!-- 地区选择器 -->
            <van-popup v-model="showPicker.area" position="bottom">
                <van-area
                    ref="areaPicker"
                    title="地区"
                    :area-list="columns.area"
                    :columns-placeholder="['请选择', '请选择', '请选择']"
                    value="440100"
                    cancel-button-text=""
                    @confirm="onConfirmArea"
                    @cancel="onCancelArea"
                />
            </van-popup>
            <!-- 街道选择器 -->
            <van-popup v-model="showPicker.street" position="bottom">
                <van-picker
                    ref="streetPicker"
                    show-toolbar
                    value-key="name"
                    :columns="columns.street"
                    @confirm="onConfirmStreet"
                    @cancel="$refs.streetPicker.setColumnValue(runtimeData.street);showPicker.street = false"
                />
            </van-popup>
        </van-form>
        <div v-else-if="step === 2" class="success">
            <div class="container">
                <van-icon class="icon-completed" name="completed" />
                <h1>已成功登记信息</h1>
                <p>尊敬的 {{ form.name }} 家长，您孩子的信息已成功登记，慈幼服务中心将竭诚为您进行服务！</p>
                <!-- <div class="btn-group">
                    <van-button color="#48be50" size="large" icon="records" plain @click="$router.push({ name: 'Survey', query: { ...$route.query }, params: { cid } })">
                        填写咨询基础问卷
                    </van-button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Moment from 'moment';
import { Http } from '@/utils/axios';
import AREA from '@/utils/area';
import { DIAGNOSIS, CHILDSOURCE, HOSPITAL } from '@/utils/constant';

export default {
    data() {
        return {
            step: null,
            cid: null,
            submitLoading: false,
            showPicker: {
                birthday: false,
                diagnosis: false,
                sourceType: false,
                hospital: false,
                area: false,
                street: false
            },
            runtimeData: {
                isDiagnosis: false,
                diagnosisType: '',
                area: '',
                street: '',
                hospital: ''
            },
            form: {
                name: '',
                phone: '',
                gender: 1,
                birthday: null,
                sourceType: 1,
                sourceRemark: '',
                addProvince: '',
                addCity: '',
                addArea: '',
                addStreet: '',
                addFull: '',
                hospital: '',
                allergy: '',
                encouragement: '',
                diagnosisType: null,
                diagnosisRemark: '',
                register: null,
                status: 1,
                storeId: this.$route.query.storeId,
                training: 0,
                trainingTime: '',
                trainingSchool: '',
                drugs: ''
            },
            columns: {
                diagnosisType: Object.keys(DIAGNOSIS).map(key => ({ value: Number(key), text: DIAGNOSIS[key] })),
                sourceType: Object.keys(CHILDSOURCE).map(key => ({ value: Number(key), text: CHILDSOURCE[key] })),
                hospital: Object.keys(HOSPITAL).map(key => ({ text: key, children: HOSPITAL[key].map(item => ({ text: item }))})),
                area: AREA,
                street: []
            },
            minDate: new Date(String(new Date().getFullYear() - 14)),
            maxDate: new Date()
        };
    },
    watch: {
        'form.addArea'(n) {
            if (n) {
                Http.get(`/api/base/bsList`, { level: 'street', parentCode: n }).then(({ data }) => {
                    this.columns.street = data;
                });
            } else {
                this.columns.street = [];
            }
        }
    },
    created() {
        const cid = window.localStorage.getItem('CY_CID');
        if (cid) {
            // 已填写过
            Http.get(`/api/wx/children/${cid}`).then(({ data }) => {
                this.form.name = data.name;
                this.cid = data.id;
                this.step = 2;
            })
            .catch(err => {
                if (err.response.status === 404) {
                    // 儿童信息已被删除
                    this.step = 1;
                    window.localStorage.clear();
                } else {
                    this.$toast.fail('系统异常');
                }
            });
        } else {
            // 未填写过
            this.step = 1;
        }
    },
    methods: {
        checkPhone(val) {
            return /^1[0-9]{10}$/.test(val);
        },
        onConfirmBirthday(time) {
            this.form.birthday = Moment(time).format('YYYY-MM-DD');
            this.showPicker.birthday = false;
        },
        onConfirmDiagnosis(data) {
            this.form.diagnosisType = data.value;
            this.runtimeData.diagnosisType = data.text;
            this.showPicker.diagnosis = false;
        },
        onConfirmSourceType(data) {
            this.form.sourceType = data.value;
            this.runtimeData.sourceType = data.text;
            this.showPicker.sourceType = false;
        },
        onConfirmArea(data) {
            let res = data.filter(item => !item || (item.code === '' && item.name === '')).length === 0;
            this.runtimeData.street = '';
            this.form.addStreet = '';
            if (res) {
                const full = data.map(item => item.name).join(' / ');
                this.runtimeData.area = full;
                this.form.addFull = full;
                this.form.addProvince = data[0].code;
                this.form.addCity = data[1].code;
                this.form.addArea = data[2].code;
                this.showPicker.area = false;
            } else {
                this.$toast({
                    message: '请选择完整的地区信息',
                    position: 'top',
                });
            }
        },
        onConfirmHospital(data) {
            this.runtimeData.hospital = data[1];
            if (data[1] !== '其他') this.form.hospital = data[1];
            this.showPicker.hospital = false;
        },
        onCancelArea() {
            this.$refs.areaPicker.reset(this.form.addArea);
            this.showPicker.area = false;
        },
        onStreetClick() {
            if (this.form.addArea) {
                this.showPicker.street = true;
            } else {
                this.$toast({
                    message: '请先选择完整的地区信息',
                    position: 'top',
                });
            }
        },
        onConfirmStreet(data) {
            this.runtimeData.street = data.name;
            this.form.addStreet = data.code;
            this.showPicker.street = false;
        },
        onSubmit() {
            this.submitLoading = true;
            const params = Object.assign({}, this.form, { addFull: `${this.form.addFull}${this.runtimeData.street ? (' / ' + this.runtimeData.street) : ''}` });
            Http.post(`/api/wx/children`, params).then(({ data }) => {
                window.localStorage.setItem('CY_CID', data.id);
                this.$toast.success('提交成功');
                // this.$router.push({ name: 'Survey', query: { ...this.$route.query }, params: { cid: data.id } });
                this.step = 2;
            })
            .catch(err => {
                if (err.response.status === 409) {
                    this.$toast({
                        message: '该家长手机号码已被注册，请检查',
                        position: 'top',
                    });
                } else {
                    this.$toast.fail('系统异常');
                }
            })
            .finally(() => {
                this.submitLoading = false;
            });
        }
    }
}
</script>

<style lang="less" scoped>
.form {
    .submit-btn {
        margin: 20px;
    }
    .tips {
        font-size: 12px;
        color: @gray-6;
        .icon {
            vertical-align: top;
            margin: 5px 6px 0 0;
        }
    }
}
.success {
    position: relative;
    width: 100vw;
    height: 100vh;
    color: #fff;
    background: @bg-gradient;
    .container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        font-size: 14px;
        text-align: center;
        transform: translate3d(0,-50%,0);
        .icon-completed {
            margin-bottom: 30px;
            padding: 15px;
            font-size: 40px;
            border: 2px solid #fff;
            border-radius: 50%;
        }
        h1 {
            margin: 0 0 15px;
            font-size: 24px;
            font-weight: 500;
            line-height: 1;
        }
        p {
            margin: 0 10% 40px;
            font-size: 12px;
            line-height: 1.5;
        }
        .btn-group {
            margin: 0 10%;
        }
    }
}
</style>