export const DIAGNOSIS = {
    1: '孤独症谱系障碍',
    2: '童年孤独症',
    3: '阿斯伯格/不典型孤独症',
    4: '多动症',
    5: '语言发育迟缓',
    6: '发育迟缓',
    7: '精神发育迟缓',
    8: '雷特综合征',
    9: '童年情绪障碍',
    10: '情绪行为障碍',
    11: '学习障碍',
    99: '其他'
};

export const CHILDSOURCE = {
    1: '家长转介绍',
    2: '互联网',
    3: '传单广告',
    4: '医院',
    5: '学校',
    99: '其他'
};

export const HOSPITAL = {
    '广州': [
        '中山三院',
        '中山大学孙逸仙纪念医院',
        '广州市妇女儿童医疗中心',
        '广东省妇幼保健院',
        '广东省人民医院',
        '广州医科大学附属脑科医院',
        '广医一院',
        '暨南大学附属第一医院',
        '南方医科大学珠江医院',
        '南方医科大学南方医院',
        '其他'
    ],
    '深圳': [
        '深圳儿童医院',
        '深圳市康宁医院',
        '其他'
    ],
    '珠海': [
        '珠海妇幼保健院',
        '其他'
    ],
    '中山': [
        '中山市人民医院',
        '其他'
    ],
    '江门': [
        '江门市妇幼保健院',
        '其他'
    ],
    '其他': [
        '其他'
    ]
};

export const STORE = {
    1: '棠安路融合中心',
    2: '新达路评估干预中心'
};

export const FEEDBACKTYPE = {
    1: '环境建设',
    2: '人员投诉',
    3: '活动建议',
    99: '其他'
}

export const ARRANGETYPE = {
    1: { name: "孩子请假" },
    2: { name: "我请假", subname: "请假没有人代我课时选这个" },
    3: { name: "正常排课", subname: "正常上课，但与课表不一致时选这个" },
    4: { name: "补课" },
    5: { name: "我代别人课" },
    6: { name: "别人代我课" },
    7: { name: "无上课", subname: "非以上原因无上课时选这个" }
};

export const COURSETYPE = {
    1: '个训课',
    2: '小组课',
    3: '融合小组课',
    4: '融合幼儿园'
}