import axios from 'axios';

const API_HOST = window.location.origin;

export const Axios = axios.create({
    baseURL: API_HOST,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 60000
});

export const Http = {
    get: (url, params) => Axios({ method: 'get', url, params }),
    post: (url, data) => Axios({ method: 'post', url, data }),
    put: (url, data) => Axios({ method: 'put', url, data }),
    delete: (url, data) => Axios({ method: 'delete', url, data })
};