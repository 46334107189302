<template>
    <div class="ciyou">
        <div class="container">
            <div class="logo">
                <img src="@assets/logo.png" width="160px">
            </div>
            <h1>欢迎光临 Welcome</h1>
            <p v-if="storeId" class="location">
                <van-icon class="icon-location" name="location" />
                <span>{{ constant.STORE[storeId] }}</span>
            </p>
            <p class="desc">
                温馨提示：为了更好地为您和您的孩子进行服务，我们需要收集一些关于您孩子现状的信息，该信息仅用于帮助老师快速了解孩子现状和后续提供给家长更多的线上服务。
            </p>
            <div class="btn">
                <van-button color="#34a772" size="large" plain @click="$router.push({ name: 'Form', query: { ...$route.query } })">
                    立即开始
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { STORE } from '@/utils/constant';
export default {
    name: "Home",
    data() {
        return {
            storeId: this.$route.query.storeId || null,
            constant: {
                STORE
            }
        };
    }
};
</script>

<style lang="less" scoped>
.ciyou {
    position: relative;
    width: 100vw;
    height: 100vh;
    color: #fff;
    background: @bg-gradient;
    .container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        font-size: 14px;
        text-align: center;
        transform: translate3d(0,-50%,0);
        .logo {
            padding-bottom: 40px;
        }
        p {
            margin: 0;
        }
        h1 {
            margin: 0 0 15px;
            font-size: 24px;
            font-weight: 500;
            line-height: 1;
        }
        .location {
            font-size: 16px;
            line-height: 1;
            .icon-location {
                vertical-align: top;
                margin-right: 10px;
            }
        }
        .desc {
            margin: 40px 10% 20px;
            padding: 10px;
            font-size: 12px;
            line-height: 1.5;
            color: rgba(255,255,255,.9);
            border: 1px dashed rgba(255,255,255,.5)
        }
        .btn {
            padding: 0 10%;
        }
    }
}
</style>