<template>
    <div class="teacher">
        <div class="image-box">
            <van-image
                width="100%"
                height="65vh"
                fit="cover"
                :src="data.avatar.url"
            />
            <div class="name">
                <strong>{{ data.name }}老师</strong>
                <!-- <span v-if="data.rank === null" class="rank-title">ABA治疗师</span>
                <span v-else-if="data.rank === 0" class="rank-title">实习</span>
                <span v-else class="rank"><i>等级：</i><van-icon name="star" class="rank-star-box"><strong class="txt">{{ data.rank }}</strong></van-icon></span> -->
            </div>
        </div>
        <van-cell-group title="慈幼老师简介">
            <van-cell title="任教科目">
                <van-tag v-for="item in data.subject" :key="item" type="primary" size="medium" style="margin-left: 8px">
                    {{ item }}
                </van-tag>
            </van-cell>
            <!-- <van-cell v-if="data.rank" title="阶梯等级">
                <span v-if="data.rank === 0">实习</span>
                <template v-else>
                    <van-icon v-for="star in Number(data.rank)" :key="star" name="star" class="rank-star" />
                </template>
            </van-cell> -->
            <van-cell title="从业日期">
                {{ teachAgeCount }}年（{{ teachYear }}年起）
            </van-cell>
            <van-cell v-if="data.eduSchool" title="毕业院校" :value="data.eduSchool" />
            <van-cell v-if="data.major" title="毕业专业" :value="data.major" />
            <van-cell title="自我介绍" />
            <van-cell>
                <template #title>
                    <div class="content ident">
                        {{ data.brief }}
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group class="last" title="教学经验介绍">
            <template v-if="data.teachStyle">
                <van-cell title="教学风格" />
                <van-cell>
                    <template #title>
                        <div class="content">
                            {{ data.teachStyle }}
                        </div>
                    </template>
                </van-cell>
            </template>
            <van-cell v-if="data.teachNum" title="教学个案积累" :value="data.teachNum" />
            <template v-if="data.achievement.data.length > 0">
                <van-cell title="培训经历或所获证书" />
                <van-cell>
                    <template #title>
                        <div class="content">
                            <ul>
                                <li v-for="(item, i) in data.achievement.data" :key="i">
                                    <van-icon name="star" class="icon" />{{ item.content }}
                                </li>
                            </ul>
                        </div>
                    </template>
                </van-cell>
            </template>
            <template v-if="data.exp.data.length > 0">
                <van-cell title="从业履历" />
                <van-cell>
                    <template #title>
                        <div class="content">
                            <ul>
                                <li v-for="(item, i) in data.exp.data" :key="i">
                                    <strong v-if="item.start" class="date">{{ item.start | timeFormat('YYYY/MM') }} ~ <span v-if="item.end">{{ item.end | timeFormat('YYYY/MM') }}</span><span v-else>至今</span></strong>
                                    {{ item.content }}
                                </li>
                            </ul>
                        </div>
                    </template>
                </van-cell>
            </template>
        </van-cell-group>
    </div>
</template>

<script>
import Moment from 'moment';
import { Http } from '@/utils/axios';

export default {
    filters: {
        timeFormat(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
            return dataStr ? Moment(dataStr).format(pattern) : '--';
        }
    },
    data() {
        return {
            data: {
                avatar: {
                    key: '',
                    url: ''
                },
                name: '',
                rank: '',
                subject: [],
                teachAge: '',
                teachStyle: '',
                teachNum: '',
                brief: '',
                eduSchool: '',
                major: '',
                achievement: { data: [] },
                exp: { data: [] }
            }
        };
    },
    computed: {
        teachAgeCount({ data }) {
            return Moment().diff(data.teachAge, 'years');
        },
        teachYear({ data }) {
            return Moment(data.teachAge).year();
        }
    },
    created() {
        document.body.scrollTop = 0;
        Http.get(`/api/wx/staffs/${this.$route.params.id}`).then(({ data }) => {
            Object.keys(this.data).forEach(key => {
                if (data[key]) this.data[key] = data[key];
                if (data.intro[key] || data.intro[key] === null || data.intro[key] === 0) this.data[key] = data.intro[key];
            });
        });
    }
}
</script>

<style lang="less" scoped>
.teacher {
    .image-box {
        position: relative;
        margin-bottom: 20px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        overflow: hidden;
        box-shadow: 0 10px 10px rgba(0,0,0, .05);
        line-height: 0;
        .name {
            position: absolute;
            display: flex;
            align-items: center;
            bottom: 30px;
            left: 30px;
            right: 30px;
            height: 20px;
            padding: 15px;
            background-color: rgba(255,255,255,.9);
            border-radius: 10px;
            font-size: 20px;
            font-weight: 500;
            box-shadow: 0 5px 5px rgba(0,0,0, .05);
        }
        .rank {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 5px 10px;
            font-size: 18px;
            line-height: 0;
            background: linear-gradient(45deg, #eae94a 0%, #f4bd44 100%);
            border-radius: 4px;
            box-shadow: 5px 0 5px rgba(0,0,0, .05);
            i {
                font-size: 12px;
                font-style: normal;
            }
            .rank-star-box {
                position: relative;
                font-size: 26px;
                .txt {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    margin-top: -7px;
                    font-size: 12px;
                    text-align: center;
                    color: #eae94a;
                }
            }
        }
        .rank-title {
            margin-left: auto;
            font-size: 12px;
            line-height: 1;
            padding: 5px 10px;
            background: linear-gradient(45deg, #eae94a 0%, #f4bd44 100%);
            border-radius: 4px;
            box-shadow: 5px 0 5px rgba(0,0,0, .05);
        }
    }
    .content {
        color: @gray-6;
        &.ident {
            text-indent: 28px;
        }
        li {
            margin-bottom: 10px;
            word-break: break-all;
            &:last-child {
                margin-bottom: 0;
            }
            .icon {
                vertical-align: top;
                margin: 4px 8px 0 0;
                color: @gray-5;
            }
            .date {
                font-size: 12px;
                margin-right: 15px;
                color: @gray-5;
            }
        }
    }
    .last {
        margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
        margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    }
    .rank-star {
        vertical-align: top;
        margin: 5px 0 0 5px;
        color: @orange;
    }
}
</style>