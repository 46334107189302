<template>
    <div class="arrange-item" :class="{vacate: arrange.isStaffVacate, change: arrange.changeData}" @click="clickFun">
        <div class="schedule-info">
            <van-tag v-if="arrange.isStaffVacate" style="margin-right: 10px">
                请假
            </van-tag>
            <van-tag v-if="arrange.changeData" color="#ffc857" text-color="#621708" style="margin-right: 10px">
                修改后
            </van-tag>
            <strong>第{{ item.sort }}节</strong>
            <span class="timerange">{{ item.start | hourFormat }} ~ {{ item.end | hourFormat }}</span>
        </div>
        <div class="arrange-info">
            <template v-if="arrange.changeData">
                <template v-if="[1, 2, 6, 7].includes(arrange.changeData.arrangeTypeKey)">
                    <span style="margin-right: auto">{{ ARRANGETYPE[arrange.changeData.arrangeTypeKey].name }}<span v-if="arrange.changeData.arrangeTypeKey === 6">【{{ arrange.changeData.subStaff }}】</span></span>
                    <div v-if="arrange.changeData.arrangeTypeKey === 6" class="tag-box">
                        <span style="font-size: 12px">{{ arrange.child.name }}</span>
                        <van-tag color="#666" size="medium" style="margin-left: 10px">
                            {{ arrange.courseName }}
                        </van-tag>
                    </div>
                </template>
                <template v-else>
                    <strong class="child-name">{{ arrange.changeData.child }}</strong>
                    <div class="tag-box">
                        <van-tag color="#666" size="medium">
                            {{ arrange.changeData.course }}
                        </van-tag>
                        <van-tag v-if="arrange.changeData.arrangeTypeKey === 4" type="danger" size="medium" style="margin-left: 5px">
                            补课
                        </van-tag>
                        <van-tag v-if="arrange.changeData.arrangeTypeKey === 5" color="#1989fa" size="medium" style="margin-left: 5px">
                            代课
                        </van-tag>
                        <p class="store">
                            <van-icon name="location" style="margin-right: 5px" />{{ STORE[arrange.changeData.storeId] }}
                        </p>
                    </div>
                </template>
            </template>
            <template v-else>
                <template v-if="arrange.arrange">
                    <span class="child-color" :style="{ backgroundColor: arrange.child.color }" />
                    <strong class="child-name">{{ arrange.child.name }}</strong>
                    <div class="tag-box">
                        <van-tag color="#666" size="medium">
                            {{ arrange.courseName }}
                        </van-tag>
                        <template v-if="arrange.isMiss">
                            <van-tag v-if="arrange.childcourseMiss.subStaffId === null" type="danger" size="medium" style="margin-left: 5px">
                                补课
                            </van-tag>
                            <van-tag v-else color="#1989fa" size="medium" style="margin-left: 5px">
                                代课
                            </van-tag>
                        </template>
                        <p class="store">
                            <van-icon name="location" style="margin-right: 5px" />{{ arrange.childcourse.store.name }}
                        </p>
                    </div>
                </template>
                <span v-else class="null-arrange">{{ arrange.isStaffVacate ? '老师已请假' : '暂无排课' }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import { ARRANGETYPE, STORE } from '@/utils/constant';

export default {
    name: 'ArrangeItem',
    filters: {
        hourFormat(value) {
            const h = value.split(':');
            h.pop();
            return h.join(':');
        }
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        arrangeList: {
            type: Object,
            default: () => ({})
        },
        changeArrangeList: {
            type: Object,
            default: () => ({})
        },
        vacate: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            ARRANGETYPE,
            STORE
        };
    },
    computed: {
        arKey({item, arrangeList}) {
            return arrangeList[item.id] ? item.id : (arrangeList[item.linkId] ? item.linkId : null);
        },
        arrange({item, arrangeList, changeArrangeList, vacate, arKey}) {
            const _arrange = { arrange: true };
            Object.assign(_arrange, arKey ? arrangeList[arKey] : { arrange: false });
            
            if (arKey) {
                if (changeArrangeList[arKey]) _arrange.changeData = changeArrangeList[arKey];
            } else {
                if (changeArrangeList[item.id]) _arrange.changeData = changeArrangeList[item.id];
            }
            
            _arrange.isStaffVacate = vacate.length > 0 && (vacate.includes(item.id) || vacate.includes(item.linkId));
            return _arrange;
        }
    },
    methods: {
        clickFun() {
            this.$emit('clickCallback', this.arrange, this.arKey ? this.arKey : this.item.id, this.item);
        }
    }
}
</script>

<style lang="less" scoped>
.arrange-item {
    padding: 20px;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1;
    &.change {
        background-color: #f9f9f9;
    }
    &.vacate {
        background-color: #f2f2f2;
    }
    .schedule-info {
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        .timerange {
            margin-left: auto;
            color: #aaa;
        }
    }
    .arrange-info {
        display: flex;
        align-items: center;
        font-size: 16px;
        .child-color {
            width: 6px;
            height: 18px;
            margin-right: 8px;
            border-radius: 3px;
        }
        .child-name {
            margin-right: auto;
            font-size: 18px;
        }
        .tag-box {
            text-align: right;
            line-height: 1;
            .store {
                margin: 10px 0 0;
                font-size: 12px;
                color: #999;
            }
        }
    }
    .null-arrange {
        color: #999;
    }
}
</style>