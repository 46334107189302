<template>
    <div class="survey">
        <h1>咨询基础问卷</h1>
        <van-cell-group>
            <van-cell :label="curQuest.desc">
                <div slot="title">
                    <van-tag v-if="curQuest.required" type="warning" style="margin-right: 10px">
                        必填
                    </van-tag>
                    <span>{{ index + 1 }}. {{ curQuest.question }}</span>
                </div>
            </van-cell>
            <template v-if="curQuest.type === 1">
                <van-radio-group v-model="form[curQuest.id]" icon-size="16px">
                    <van-cell v-for="(v, k) in curQuest.anwser" v-show="!k.includes('remark')" :key="k">
                        <van-radio :name="k">
                            {{ v }}
                        </van-radio>
                    </van-cell>
                </van-radio-group>
                <van-field
                    v-if="form[curQuest.id] && curQuest.remark && curQuest.remarkId.includes(form[curQuest.id])"
                    v-model="form[curQuest.remarkId]"
                    rows="2"
                    autosize
                    type="textarea"
                    maxlength="144"
                    placeholder="请输入补充信息"
                    show-word-limit
                />
            </template>
            <template v-else-if="curQuest.type === 2">
                <van-checkbox-group v-model="form[curQuest.id]" icon-size="16px" direction="horizontal">
                    <van-cell v-for="(v, k) in curQuest.anwser" :key="k">
                        <van-checkbox :name="k" shape="square">
                            {{ v }}
                        </van-checkbox>
                    </van-cell>
                </van-checkbox-group>
            </template>
            <template v-else-if="curQuest.type === 3">
                <van-field
                    v-model="form[curQuest.id]"
                    rows="2"
                    autosize
                    type="textarea"
                    maxlength="120"
                    placeholder="请输入"
                    show-word-limit
                />
            </template>
        </van-cell-group>
        <div class="ctrl-box">
            <van-progress :percentage="percentage" />
            <div class="btn-group">
                <div v-if="index > 0" class="item prev">
                    <van-button color="#48be50" size="mini" plain @click="prev">
                        上一题
                    </van-button>
                </div>
                <div class="item" :class="{ctrl: index === survey.length - 1}">
                    <span v-if="index < survey.length - 1" class="progress"> {{ index + 1 }} / {{ survey.length }}</span>
                    <van-button v-else color="#48be50" size="mini" block @click="submit">
                        提 交 问 卷
                    </van-button>
                </div>
                <div v-if="index < survey.length - 1" class="item next">
                    <van-button color="#48be50" size="mini" plain @click="next">
                        下一题
                    </van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Http } from '@/utils/axios';

export default {
    data() {
        return {
            type: 3,
            index: 0,
            cid: this.$route.params.cid,
            survey: [],
            curQuest: {},
            form: {}
        };
    },
    computed: {
        checkAnwser() {
            const { type, id, remark, remarkId } = this.curQuest;
            let checkAnwser = true;
            switch(type) {
                case 1:
                    if (!this.form[id]) checkAnwser = false;
                    if (remark && remarkId.includes(this.form[id]) && !this.form[remarkId]) checkAnwser = false;
                    break;
                case 3:
                    if (!this.form[id]) checkAnwser = false;
                    break;
                case 2:
                    if (this.form[id].length === 0) checkAnwser = false;
                    break;
            }
            return checkAnwser;
        },
        percentage() {
            let i = this.index;
            if (this.checkAnwser) i = this.index + 1;
            const len = this.survey.length;

            return len > 0 ? Math.floor(i/len * 100) : 0;
        }
    },
    created() {
        Http.get('/api/wx/survey/base/listAll').then(({data}) => {
            data.forEach(item => {
                switch(item.type) {
                    case 1:
                        this.$set(this.form, item.id, '');
                        Object.keys(item.anwser).forEach(key => {
                            if (key.includes('remark')) {
                                item.remark = true;
                                item.remarkId = key;
                                this.$set(this.form, key, '');
                            }
                        });
                        break;
                    case 2:
                        this.$set(this.form, item.id, []);
                        break;
                    case 3:
                        this.$set(this.form, item.id, '');
                        break;
                }

                this.survey = data;
                this.curQuest = Object.assign({}, data[this.index]);
            })
            return Http.get(`/api/wx/children/${this.cid}`);
        }).then(({ data }) => {
            if (data.surveyBase) {
                const _anwser = data.surveyBase;
                Object.keys(_anwser).forEach(key => {
                    this.form[key] = _anwser[key];
                });
            }
        })
        .catch(() => {
            this.$toast.fail('系统异常');
        });
    },
    methods: {
        prev() {
            this.index--;
            this.curQuest = Object.assign({}, this.survey[this.index]);
        },
        next() {
            const { required } = this.curQuest;
            if (!required || (required && this.checkAnwser)) {
                this.index++;
                this.curQuest = Object.assign({}, this.survey[this.index]);
            } else {
                this.$toast('请完善该题目后再进行下一题');
            }
        },
        submit() {
            Http.put(`/api/wx/children/${this.cid}`, {surveyBase: this.form})
                .then(({ data }) => {
                    this.$toast.success('提交成功');
                    this.$router.push({ name: 'Form', query: { storeId: this.$route.query.storeId }});
                })
                .catch(() => {
                    this.$toast.fail('系统异常');
                });
        }
    }
}
</script>

<style lang="less" scoped>
.survey {
    margin-bottom: 100px;
    h1 {
        margin: 20px 0;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1;
    }
    .ctrl-box {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 70px;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        background-color: #fff;
        .btn-group {
            margin-top: 12px;
            display: flex;
            .item {
                flex: 1;
                padding: 10px 20px;
                text-align: center;
                &.ctrl {
                    padding-left: 0;
                }
                &.prev {
                    text-align: left;
                }
                &.next {
                    text-align: right;
                }
            }
            .progress {
                font-size: 12px;
                line-height: 24px;
            }
        }
    }
}
</style>