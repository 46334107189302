<template>
    <div class="teacherList">
        <van-sticky>
            <van-search
                v-model="searchAttrs.name"
                class="search-box"
                placeholder="请输入需要搜索老师的名字"
                @search="onSearch"
            />
        </van-sticky>
        <van-list
            v-model="loading"
            class="list"
            :finished="finished"
            :error.sync="error"
            error-text="网络开小差了，请稍后重试"
            @load="onLoad"
        >
            <div v-for="item in data.list" :key="item.id" class="item" @click="$router.push(`/teachers/${item.id}`)">
                <van-image
                    class="item-avatar"
                    round
                    width="60px"
                    height="60px"
                    :src="item.avatar.url"
                    fit="cover"
                />
                <div class="item-info">
                    <p class="name">
                        {{ item.name }}老师
                    </p>
                    <!-- <p class="rank">
                        <span v-if="item.intro.rank === null">ABA治疗师</span>
                        <span v-else-if="item.intro.rank === 0" class="practice">实习</span>
                        <template v-else>
                            <van-icon v-for="star in item.intro.rank" :key="star" name="star" class="rank-star" />
                        </template>
                    </p> -->
                    <p class="item-series van-hairline--top">
                        <van-tag v-for="subject in item.intro.subject" :key="subject" type="primary" size="medium" style="margin-right: 5px; margin-top: 5px">
                            {{ subject }}
                        </van-tag>
                    </p>
                </div>
            </div>
            <div slot="finished">
                期待你的加入 <a class="link" href="https://www.zhipin.com/gongsir/81368283e3e851e21nF-3N64EFM~.html?ka=company-jobs"><van-icon name="comment-o" /> 慈幼服务中心招聘信息</a>
            </div>
        </van-list>
    </div>
</template>

<script>
import { Http } from '@/utils/axios';

export default {
    data() {
        return {
            searchAttrs: {
                pageNo: 1,
                limit: 10,
                name: ''
            },
            loading: false,
            finished: false,
            error: false,
            data: {
                total: 0,
                list: []
            }
        };
    },
    methods: {
        onSearch() {
            Http.get(`/api/wx/staffs`, Object.assign(this.searchAttrs, { pageNo: 1 }))
                .then(({ data }) => {
                    this.searchAttrs.pageNo++;

                    this.data.total = data.count;
                    this.data.list = data.rows;
                })
                .catch(() => {
                    this.$toast('网络开小差了，请稍后重试');
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onLoad() {
            Http.get(`/api/wx/staffs`, this.searchAttrs)
                .then(({ data }) => {
                    this.data.total = data.count;

                    if (this.searchAttrs.name !== '' || this.searchAttrs.pageNo === 1) {
                        this.data.list = data.rows;
                    } else {
                        this.data.list = this.data.list.concat(data.rows);
                    }

                    if (this.data.list.length >= data.count) {
                        this.finished = true;
                    }

                    this.searchAttrs.pageNo++;
                })
                .catch(() => {
                    this.$toast('网络开小差了，请稍后重试');
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style lang="less" scoped>
.teacherList {
    .search-box {
        box-shadow: 0 4px 8px rgba(0,0,0,.05);
    }
    .list {
        padding: 20px;
        .item {
            display: flex;
            align-items: center;
            padding: 15px 10px;
            margin-bottom: 15px;
            border-radius: 4px;
            background-color: #FFF;
        }
        .item-info {
            width: calc(100% - 70px);
            margin-left: 20px;
            p {
                margin: 0 0 10px 0;
                line-height: 1;
                &.name {
                    font-size: 16px;
                    margin-bottom: 14px;
                }
                &.rank {
                    font-size: 12px;
                    color: #ffba20;
                    .rank-star {
                        margin-right: 3px;
                    }
                }
                &.item-series {
                    padding-top: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .link {
        color: @blue;
    }
}
</style>