import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { 
    Button,
    Icon,
    Cell,
    CellGroup,
    Form,
    Field,
    Checkbox,
    CheckboxGroup,
    Picker,
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Switch,
    Progress,
    Tag,
    Toast,
    Area,
    Image as VanImage,
    Search,
    Sticky,
    List,
    Dialog,
    Calendar,
    ActionSheet,
    Loading,
    NoticeBar
} from 'vant';
import 'vant/lib/index.less';

Vue.use(Button);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Picker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(Tag);
Vue.use(Toast);
Vue.use(Area);
Vue.use(VanImage);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Calendar);
Vue.use(ActionSheet);
Vue.use(Loading);
Vue.use(NoticeBar);

// import VConsole from 'vconsole';
// new VConsole();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
